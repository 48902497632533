<template>
  <div>
    <v-form>
      <h2 class="mb-4">Coffee Cup Calorimetry - Data Sheet</h2>

      <p class="mb-3">
        a) Please enter your data for the first part of the experiment where a sample of hot water
        was mixed with a sample of cold water.
      </p>

      <v-simple-table style="max-width: 431px" class="mb-6">
        <thead>
          <tr>
            <td style="text-align: left; font-weight: bold" class="pl-2">Property</td>
            <td style="text-align: center; font-weight: bold">Value</td>
          </tr>
          <tr v-for="option in options1" :key="option.input">
            <td class="my-1 py-0">
              <stemble-latex :content="option.text" />
            </td>
            <td class="centered-input my-1 py-0">
              <calculation-input v-model="inputs[option.input]" dense :disabled="!allowEditing" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-3">
        b) Please enter your data for the second part of the experiment where the thermodynamics of
        the neutralization between <chemical-latex content="HCl" /> and
        <chemical-latex content="NaOH" /> was observed.
      </p>

      <v-simple-table style="max-width: 431px" class="mb-6">
        <thead>
          <tr>
            <td style="text-align: left; font-weight: bold" class="pl-2">Property</td>
            <td style="text-align: center; font-weight: bold">Value</td>
          </tr>
          <tr v-for="option in options2" :key="option.input">
            <td class="my-1 py-0">
              <stemble-latex :content="option.text" />
            </td>
            <td class="centered-input my-1 py-0">
              <calculation-input v-model="inputs[option.input]" dense :disabled="!allowEditing" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-3">
        c) Please enter your data for the second part of the experiment where the thermodynamics of
        the neutralization between acetic acid and <chemical-latex content="NaOH" /> was observed.
      </p>

      <v-simple-table style="max-width: 431px" class="mb-6">
        <thead>
          <tr>
            <td style="text-align: left; font-weight: bold" class="pl-2">Property</td>
            <td style="text-align: center; font-weight: bold">Value</td>
          </tr>
          <tr v-for="option in options3" :key="option.input">
            <td class="my-1 py-0">
              <stemble-latex :content="option.text" />
            </td>
            <td class="centered-input my-1 py-0">
              <calculation-input v-model="inputs[option.input]" dense :disabled="!allowEditing" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-3">
        d) Please use the data from the previous parts of the question to determine the reaction
        enthalpies for the two neutralization reactions.
      </p>

      <v-simple-table class="mb-6">
        <tr>
          <th></th>
          <th><chemical-latex content="HCl Rxn" /></th>
          <th><chemical-latex content="HOAc Rxn" /></th>
        </tr>

        <tr>
          <td><stemble-latex content="$\text{[Acid] (M)}$" /></td>
          <td style="text-align: center">
            <stemble-latex content="$1.00$" />
          </td>
          <td style="text-align: center">
            <stemble-latex content="$1.00$" />
          </td>
        </tr>

        <tr>
          <td><stemble-latex content="$\text{[NaOH] (M)}$" /></td>
          <td style="text-align: center">
            <stemble-latex content="$1.00$" />
          </td>
          <td style="text-align: center">
            <stemble-latex content="$1.00$" />
          </td>
        </tr>

        <tr>
          <td>Volume of acid used (mL)</td>
          <td style="text-align: center">
            <stemble-latex content="$25.00$" />
          </td>
          <td style="text-align: center">
            <stemble-latex content="$25.00$" />
          </td>
        </tr>

        <tr>
          <td>Volume of NaOH used (mL)</td>
          <td style="text-align: center">
            <stemble-latex content="$25.00$" />
          </td>
          <td style="text-align: center">
            <stemble-latex content="$25.00$" />
          </td>
        </tr>

        <tr>
          <td>Moles <stemble-latex content="$\ce{H2O}$" /> formed (mol)</td>
          <td class="centered-input">
            <calculation-input v-model="inputs.nH2OFormed2" :disabled="!allowEditing" />
          </td>
          <td class="centered-input">
            <calculation-input v-model="inputs.nH2OFormed3" :disabled="!allowEditing" />
          </td>
        </tr>

        <tr>
          <td>
            <stemble-latex content="$\text{q}_\text{rxn}\,\text{(J)}$" />
          </td>
          <td class="centered-input">
            <calculation-input v-model="inputs.qRxn2" disabled />
          </td>
          <td class="centered-input">
            <calculation-input v-model="inputs.qRxn3" disabled />
          </td>
        </tr>

        <tr>
          <td><stemble-latex content="$\Delta\text{H}_\text{rxn}\,\text{(J/mol)}:$" /></td>
          <td class="centered-input">
            <calculation-input v-model="inputs.deltaHRxn2" :disabled="!allowEditing" />
          </td>
          <td class="centered-input">
            <calculation-input v-model="inputs.deltaHRxn3" :disabled="!allowEditing" />
          </td>
        </tr>
      </v-simple-table>

      <p class="mb-3">
        e) Use the chemical price list shown here to complete the cost analysis below. Please round
        answers to three significant figures.
      </p>

      <p class="mb-8 pl-6">
        Hydrochloric Acid
        <chemical-latex content="(HCl):" />
        <stemble-latex content="$\;\$29.50 / 20.00\,\text{L}$" />
        <br /><br />
        Acetic acid
        <chemical-latex content="(HOAc):" />
        <stemble-latex content="$\;\$61.31 / 20.00\,\text{L}$" />
        <br /><br />
        Sodium hydroxide
        <chemical-latex content="(NaOH):" />
        <stemble-latex content="$\;\$44.96 / 20.00\,\text{L}$" />
      </p>

      <v-simple-table>
        <tr>
          <th></th>
          <th><chemical-latex content="HCl Rxn" /></th>
          <th><chemical-latex content="HOAc Rxn" /></th>
        </tr>

        <tr>
          <td>Cost per <stemble-latex content="$25\,\text{mL}$" /> of acid</td>
          <td class="centered-input">
            <calculation-input
              v-model="inputs.costPerAcidRxn2"
              prepend-text="$\$$"
              :disabled="!allowEditing"
            />
          </td>
          <td class="centered-input">
            <calculation-input
              v-model="inputs.costPerAcidRxn3"
              prepend-text="$\$$"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>
            Cost per
            <stemble-latex content="$25\,\text{mL}$" />
            of
            <chemical-latex content="NaOH" />
          </td>
          <td class="centered-input">
            <calculation-input
              v-model="inputs.costPerSodiumHydroxide"
              prepend-text="$\$$"
              :disabled="!allowEditing"
            />
          </td>
          <td class="centered-input">
            <calculation-input
              v-model="inputs.costPerSodiumHydroxide"
              prepend-text="$\$$"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>Cost for reaction</td>
          <td class="centered-input">
            <calculation-input
              v-model="inputs.costRxn2"
              prepend-text="$\$$"
              :disabled="!allowEditing"
            />
          </td>
          <td class="centered-input">
            <calculation-input
              v-model="inputs.costRxn3"
              prepend-text="$\$$"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>
            <stemble-latex content="$\text{q}_\text{rxn}\,\text{(J)}$" />
          </td>
          <td class="centered-input">
            <calculation-input v-model="inputs.qRxn2" disabled />
          </td>
          <td class="centered-input">
            <calculation-input v-model="inputs.qRxn3" disabled />
          </td>
        </tr>

        <tr>
          <td>Cost per Joule</td>
          <td class="centered-input">
            <calculation-input
              v-model="inputs.costPerJouleRxn2"
              prepend-text="$\$$"
              :disabled="!allowEditing"
            />
          </td>
          <td class="centered-input">
            <calculation-input
              v-model="inputs.costPerJouleRxn3"
              prepend-text="$\$$"
              :disabled="!allowEditing"
            />
          </td>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '../inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemOleMissLab7_Task1',
  components: {ChemicalLatex, StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        massCold: null,
        massHot: null,
        TcoldBefore: null,
        ThotBefore: null,
        TAfter1: null,
        deltaTCold: null,
        deltaTHot: null,
        qCold: null,
        qHot: null,
        qCal1: null,
        cCal: null,
        massRA2: null,
        massRB2: null,
        TBefore2: null,
        TAfter2: null,
        deltaT2: null,
        qSoln2: null,
        qCal2: null,
        qRxn2: null,
        massRA3: null,
        massRB3: null,
        TBefore3: null,
        TAfter3: null,
        deltaT3: null,
        qSoln3: null,
        qCal3: null,
        qRxn3: null,
        nH2OFormed2: null,
        nH2OFormed3: null,
        deltaHRxn2: null,
        deltaHRxn3: null,
        costPerAcidRxn2: null,
        costPerAcidRxn3: null,
        costPerSodiumHydroxide: null,
        costRxn2: null,
        costRxn3: null,
        costPerJouleRxn2: null,
        costPerJouleRxn3: null,
      },
      options1: [
        {text: '$\\text{m}_\\text{cold}\\,\\text{(g)}$', input: 'massCold'},
        {text: '$\\text{m}_\\text{hot}\\,\\text{(g)}$', input: 'massHot'},
        {
          text: '$\\text{T}_\\text{cold}\\text{ before mixing }(^\\circ\\text{C})$',
          input: 'TcoldBefore',
        },
        {
          text: '$\\text{T}_\\text{hot}\\text{ before mixing }(^\\circ\\text{C})$',
          input: 'ThotBefore',
        },
        {text: '$\\text{T after mixing }(^\\circ\\text{C})$', input: 'TAfter1'},
        {text: '$\\Delta\\text{T}_\\text{cold}\\,(^\\circ\\text{C})$', input: 'deltaTCold'},
        {text: '$\\Delta\\text{T}_\\text{hot}\\,(^\\circ\\text{C})$', input: 'deltaTHot'},
        {text: '$\\text{q}_\\text{cold}\\,\\text{(J)}$', input: 'qCold'},
        {text: '$\\text{q}_\\text{hot}\\,\\text{(J)}$', input: 'qHot'},
        {text: '$\\text{q}_\\text{cal}\\,\\text{(J)}$', input: 'qCal1'},
        {text: '$\\text{C}_\\text{cal}\\,\\text{(J/}^\\circ\\text{C)}$', input: 'cCal'},
      ],
      options2: [
        {text: '$\\text{C}_\\text{cal}\\,\\text{(J/}^\\circ\\text{C)}$', input: 'cCal'},
        {text: '$\\text{m}_\\text{HCl}\\,\\text{(g)}$', input: 'massRA2'},
        {text: '$\\text{m}_\\text{NaOH}\\,\\text{(g)}$', input: 'massRB2'},
        {text: '$\\text{T before mixing }(^\\circ\\text{C})$', input: 'TBefore2'},
        {text: '$\\text{T after mixing }(^\\circ\\text{C})$', input: 'TAfter2'},
        {text: '$\\Delta\\text{T}_\\text{soln}\\,(^\\circ\\text{C})$', input: 'deltaT2'},
        {text: '$\\text{q}_\\text{soln}\\,\\text{(J)}$', input: 'qSoln2'},
        {text: '$\\text{q}_\\text{cal}\\,\\text{(J)}$', input: 'qCal2'},
        {text: '$\\text{q}_\\text{HCl rxn}\\,\\text{(J)}$', input: 'qRxn2'},
      ],
      options3: [
        {text: '$\\text{C}_\\text{cal}\\,\\text{(J/}^\\circ\\text{C)}$', input: 'cCal'},
        {text: '$\\text{m}_\\text{AcOH}\\,\\text{(g)}$', input: 'massRA3'},
        {text: '$\\text{m}_\\text{NaOH}\\,\\text{(g)}$', input: 'massRB3'},
        {text: '$\\text{T before mixing }(^\\circ\\text{C})$', input: 'TBefore3'},
        {text: '$\\text{T after mixing }(^\\circ\\text{C})$', input: 'TAfter3'},
        {text: '$\\Delta\\text{T}_\\text{soln}\\,(^\\circ\\text{C})$', input: 'deltaT3'},
        {text: '$\\text{q}_\\text{soln}\\,\\text{(J)}$', input: 'qSoln3'},
        {text: '$\\text{q}_\\text{cal}\\,\\text{(J)}$', input: 'qCal3'},
        {text: '$\\text{q}_\\text{HOAc rxn}\\,\\text{(J)}$', input: 'qRxn3'},
      ],
    };
  },
};
</script>
